<template>

    <div class="vehicle-color-area">
        <span class="via-vehicles vehicles-icon" :style="{color:selectedColor}"></span>
        <color-picker
                popper-class="color-picker-container"
                size="small"
                v-model="selectedColor"
                class="color-picker"
                @active-change="selectColor"
                @change="colorChange"
                @hide="hide"
                :predefine="colorList"
                ref="colorSelect"
        >
        </color-picker>
    </div>


</template>

<script>
    import ColorPicker from "../common/elementUI/color-picker"
    export default {
        name: "ColorSelector",
        components: {
            ColorPicker
        },
        data() {
            return {
                selectedColor: '',
                colorList: [
                    "#4e5de9",
                    "#75a8f7",
                    "#b9568a",
                    "#e485b6",
                    "#f2c220",
                    "#cc8d1f",
                    "#51d4c3",
                    "#30ab9e",
                    "#87b525",
                    "#addc48"
                ]
            }
        },

        props: {
            defaultColor: {type: String, default: "#4c4c4c"}
        },
        watch: {
            defaultColor(newVal) {
                this.selectedColor = newVal;
                if (!this.colorList.includes(newVal.toLowerCase())) {
                    if (this.colorList.length >= 30) {
                        this.colorList.shift();
                    }
                    this.colorList.push(newVal);
                }

            },

        },
        methods: {
            selectColor(data) {
                this.selectedColor = data;
            },
            colorChange(data) {
                if (!data) {
                    this.selectedColor = this.defaultColor;
                } else {
                    this.$emit("selectColor", data);
                }


            },
            hide() {
                this.selectedColor = this.defaultColor;
            }
        },
        created() {

        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"

    .color-picker-container {
        width: 200px !important;
    }

    .vehicle-color-area {
        font-size: 24px;
        margin: 4px 0 0 15px;
        cursor: pointer;
        height 32px
        width 64px
        position relative
        background white
        border-radius 5px
    }

    .vehicles-icon {
        margin-left 10px
    }

    .vehicle-color-area .color-picker {
        position absolute
        left: 0
        top: 0
    }

    >>> .el-color-picker--small .el-color-picker__trigger {
        height: 32px;
        width: 64px;
        border 1px solid $color_gray_third
    }

    >>> .el-color-picker__color {
        display none;
    }

    >>> .el-color-picker__icon {
        display: inline-block !important;
        width: 50%;
        margin-left: 20px
        color: $color_black_first;
        text-align: center;
    }

</style>
