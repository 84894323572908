<template>
    <div class="edit-fleet-Info">
        <el-form :model="fleetInfo" status-icon :rules="rules" ref="ruleForm" label-width="120px" label-position="left"
                 class="fleet-form">
            <table class="management-table">
                <tr>
                    <td>
                        <el-form-item prop="Name" :label="$t('vehicle.fleetName')">
                            <el-input v-model="fleetInfo.Name" style="width: 300px;float: left" size="small"></el-input>
                            <v-color-selector style="float: left" :default-color="fleetInfo.Color"
                                              @selectColor="handleSelectColor"></v-color-selector>

                        </el-form-item>
                    </td>
                </tr>
                <tr>
                    <td>
                        <el-form-item prop="Descript" :label="$t('vehicle.description')">
                            <el-input v-model="fleetInfo.Descript" size="small" :maxlength="100"
                                      show-word-limit style="width: 95%"></el-input>
                        </el-form-item>
                    </td>
                </tr>

                <tr>
                    <td>
                        <el-form-item>
                            <div slot="label" style="padding-top: 40px">{{$t('vehicle.vehicleList')}}</div>
                            <v-vehicle-mult-select
                                    v-model="fleetInfo.Vehicles"
                                    :fleet-color="fleetInfo.Color"
                                    :need-un-assigned-vehicle="true"
                            ></v-vehicle-mult-select>
                        </el-form-item>
                    </td>
                </tr>
            </table>
        </el-form>
        <el-row class="button-row">
            <el-button size="small" @click="saveFleet" class="ok-button" :loading="isSaving">
                {{$t('globalText.save')}}
            </el-button>
            <el-button size="small" class="cancel-button" @click="onCancel">{{$t('globalText.cancel')}}</el-button>
        </el-row>
    </div>


</template>

<script>
    import {getArr1DifferenceFrommArr2} from "../../../assets/js/Util"
    import vVehicleMultSelect from "../../../components/management/VehicleMultSelect"
    import vColorSelector from "../../../components/management/ColorSelector"

    export default {
        name: "AddFleet",
        components: {
            vVehicleMultSelect,
            vColorSelector
        },
        data() {
            return {
                fleetInfo: {
                    Name: "",
                    // FleetManagers: "",
                    Company: "",
                    VehiclesNumber: 0,
                    Color: ""
                },
                rules: {
                    Name: [
                        {required: true,
                            message: this.$t('tips.fleet.pleaseInputAFleetName'), // 'Please input a fleet name',
                            trigger: 'change'},
                        {min: 1, max: 15,
                            message: this.$t('globalText.aMaximumOf_X_CharactersCanBeUsed', {num: 15}), // 'A maximum of 15 characters can be used',
                            trigger: 'change'}
                    ],
                    // Descript: [
                    //     {required: false, message: 'Please input description', trigger: 'blur'}
                    // ]
                },
                isEditMode: false,
                isSaving: false,

            };
        },
        watch: {
            '$route.path'() {
                this.initFleetInfo();
            }
        },
        methods: {
            handleSelectColor(color) {
                this.fleetInfo.Color = color;
                //  console.log("this.fleetInfo.Color=" + this.fleetInfo.Color);
            },
            initFleetInfo: function () {
                if (this.$refs['ruleForm'])
                    this.$refs['ruleForm'].resetFields();
                if (this.$route.name == "editFleet") {
                    this.isEditMode = true;
                    var fleetId = this.$route.query.id;
                    this.$serverApi.fleet.getFleetData(fleetId, (data) => {
                        this.initEditFleetInfo(data);
                    })

                } else {
                    this.fleetInfo = {
                        Name: "",
                        Descript: "",
                        Vehicles: null,
                        Color: "#4c4c4c"
                    }
                }
            },
            initEditFleetInfo(fleetInfo) {
                if (fleetInfo == null)
                    return;
                this.fleetInfo = {
                    Id: fleetInfo.Id,
                    Name: fleetInfo.Name,
                    Color: fleetInfo.Color,
                    Descript: fleetInfo.Descript,
                    Vehicles: fleetInfo.Vehicles,
                    OldVehicles: fleetInfo.Vehicles
                };

            },
            saveFleet() {
                this.$refs['ruleForm'].validate((valid) => {
                    let successCallback = () => {
                        this.isSaving = false;
                        this.$message(
                            {
                                message: this.$t('globalText.savedSuccessfully'),
                                type: 'success'
                            }
                        )
                        this.$router.push({path: "/management/vehicle"}).catch();

                    };
                    let errorCallback = (error) => {
                        if(error.code === 601) {
                            let msg = this.$t('popUpMsg.fleet.fleetName_AlreadyExists', {
                                fleetName: this.fleetInfo.Name.trim()
                            }); // `${this.fleetInfo.Name.trim()} already exists.`
                            this.$customAlert(msg);
                        }
                        this.isSaving = false;
                    };
                    if (valid) {
                        this.isSaving = true;
                        if (!this.isEditMode) {
                            var addFleetInfo = {
                                Name: this.fleetInfo.Name.trim(),
                                Descript: this.fleetInfo.Descript,
                                Color: this.fleetInfo.Color,
                                ParentId: this.$store.getters.parentFleetId
                                // addUserIds: this.fleetInfo.FleetManagerIds,
                            }
                            if (this.fleetInfo.Vehicles) {
                                addFleetInfo.addVehicleIds = this.fleetInfo.Vehicles.map((item) => {
                                    return item.Id
                                })
                            }
                            this.$serverApi.fleet.addFleet(addFleetInfo, successCallback, errorCallback)
                        } else {
                            var editFleetInfo = {
                                Id: this.fleetInfo.Id,
                                Name: this.fleetInfo.Name.trim(),
                                Descript: this.fleetInfo.Descript,
                                Color: this.fleetInfo.Color,
                            }
                            var addVehicles = getArr1DifferenceFrommArr2(this.fleetInfo.Vehicles, this.fleetInfo.OldVehicles);
                            if (addVehicles)
                                editFleetInfo.addVehicleIds = addVehicles.map((item) => {
                                    return item.Id
                                });
                            var delVehicles = getArr1DifferenceFrommArr2(this.fleetInfo.OldVehicles, this.fleetInfo.Vehicles)
                            if (delVehicles) {
                                editFleetInfo.delVehicleIds = delVehicles.map((item) => {
                                    return item.Id
                                });
                            }
                            this.$serverApi.fleet.editFleet(editFleetInfo, successCallback, errorCallback);

                        }
                    } else {
                        return false;
                    }
                });

            },
            onCancel() {
                this.$router.push({path: "/management/vehicle"}).catch();
            }


        },
        created() {
            this.initFleetInfo();

            // if (this.$store.state.fleetData.managerList == null) {
            //     this.$store.dispatch("getManagerList");
            // }
            console.log("route" + this.$route.meta.title);

        },
    }
</script>

<style scoped lang="stylus">
    @import "~@/assets/css/management.styl";

    .edit-fleet-Info {
        min-height: 715px;
        padding: 30px 100px 0 40px;
        font-size: 15px;
    }


</style>

