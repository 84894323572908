<template>
    <div class="vehicle-multi-select">
        <v-vehicle-avatar v-if="selectedVehicles" v-for="item in selectedVehicles" v-bind:key="item.Id" :vehicle="item"
                          @deleteVehicle="deleteVehicle" :isShowDelete="true">
        </v-vehicle-avatar>
        <div>
            <el-popover
                    placement="right-start"
                    width="280"
                    trigger="click"
                    popper-class="popover"
                    v-model="isShowPopOverver">
                <span class="el-icon-plus add-area" slot="reference"></span>
                <v-tree ref="vtree"
                        :vehicle-checkbox="true"
                        :fleet-checkbox="true"
                        :check-strictly="false"
                        style="height: 400px;"
                        :default-vehicles="defaultVehicleIds"
                        :is-show-un-assigned-vehicle="needUnAssignedVehicle">
                    <div slot="filter-area"></div>
                </v-tree>
                <el-button size="small" type="primary" class="ok-button" @click="getSelectedVehicles"
                           style="margin-top: 20px">{{$t('globalText.oK')}}
                </el-button>
            </el-popover>
        </div>


    </div>
</template>

<script>
    import vTree from "../common/Tree"
    import vVehicleAvatar from "../management/VehicleAvatar"
    import Sort from "@/assets/js/Sort";

    export default {
        name: "VehicleMultSelect",
        components: {
            vTree,
            vVehicleAvatar
        },
        data() {
            return {
                errorHandler() {
                    return true
                },
                selectedVehicles: [],
                defaultVehicleIds: [],
                isShowPopOverver: false
            }
        },
        props: {
            defaultVehicles: {type: Array, default: null},
            fleetColor: {type: String, default: null},
            needUnAssignedVehicle: {
                type: Boolean,
                default: false
            }
        },
        model: {
            prop: 'defaultVehicles',
            event: 'VehicleChange'
        },
        watch: {
            defaultVehicles(newVal) {
                this.selectedVehicles = [...newVal];
                this.sortVehicle();
            },
            fleetColor(color) {
                this.selectedVehicles.forEach((item) => {
                    item.Color = color;
                })
            },
            selectedVehicles(newVal) {
                if (newVal)
                    this.defaultVehicleIds = newVal.map((item) => {
                        return item.Id
                    });
            }
        },
        methods: {
            getSelectedVehicles() {
                this.isShowPopOverver = false;
                if (this.$refs.vtree) {
                    let checked= this.$refs.vtree.getCheckedNodes();
                    if(checked){
                        this.selectedVehicles = checked.filter((data) => {
                            return data.type == "vehicle";
                        }).map((data) => {
                            return data.vehicleData;
                        });
                        this.sortVehicle();
                        this.$emit("VehicleChange", this.selectedVehicles);
                    }
                }

            },
            sortVehicle() {
                 this.selectedVehicles = this.selectedVehicles.sort((itemA, itemB) => {
                    return Sort.NameSort(itemA.Name, itemB.Name)
                });
            },
            deleteVehicle(id) {
                this.selectedVehicles = this.selectedVehicles.filter((item) => item.Id != id);
                this.$emit("VehicleChange", this.selectedVehicles);
            }
        },
        created() {

        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .vehicle-multi-select {
        height: 130px;
        line-height 130px
    }

    .vehicle-multi-select .photo {
        height: 54px;
        width: 54px;
    }

    .vehicle-multi-select > div {
        display: inline-block;
        float: left;
    }

    .vehicle-multi-select .add-area {
        width: 54px;
        height: 54px;
        line-height: 54px;
        text-align: center;
        background-color: $color_gray_second;
        color: $color_black_second;
        font-size: 24px;
        cursor: pointer;
        border-radius: 50px;
    }

    .vehicle-multi-select .add-area:hover {
        color: $color_blue_second
    }


</style>

<style>

    .popover {
        text-align: center;
        padding: 10px;
    }

    .popover .popper__arrow {
        /*border-right-color: #98abbd;*/
        border: none;
    }

    popover .popper__arrow::after {
        border: none;
    }

</style>
